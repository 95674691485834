import { Component, Vue } from 'vue-property-decorator';
import { cardSettingsStore } from '@/store/modules/CardSettings';
@Component
export class MixinMapScroll extends Vue {
    get translateX() {
        return cardSettingsStore.TRANSLATE_X === 0
            ? cardSettingsStore.TRANSLATE_CENTER
            : cardSettingsStore.TRANSLATE_X;
    }

    createScrollDataObject() {
        let Xmax;

        let mapScrollWrapper = this.$el.querySelector('#map-scroll-wrapper');
        let mapScrollItem = this.$el.querySelector('#map-scroll-wrapper-item');
        if (mapScrollWrapper && mapScrollItem)
            Xmax =
                mapScrollWrapper.getBoundingClientRect().width -
                mapScrollItem.getBoundingClientRect().width;
        else Xmax = 0;
        cardSettingsStore.SET_DATA({
            mapWrapper: this.$el.querySelector('#map-scroll-wrapper'),
            mapWrapperItem: this.$el.querySelector('#map-scroll-wrapper-item'),
            z: mapScrollWrapper
                ? mapScrollWrapper.getBoundingClientRect().width
                : 0,
            vw: mapScrollItem ? mapScrollItem.getBoundingClientRect().width : 0,
            Xmax: Xmax,
        });
    }

    mounted() {
        setTimeout(() => {
            cardSettingsStore.RELOAD_TRANSLATE_X();
            this.createScrollDataObject();
            window.addEventListener('resize', this.createScrollDataObject);
            window.addEventListener(
                'resize',
                cardSettingsStore.RELOAD_TRANSLATE_X,
            );
        }, 100);
    }

    beforeDestroy() {
        cardSettingsStore.RELOAD_TRANSLATE_X();
        window.removeEventListener('resize', this.createScrollDataObject);
        window.removeEventListener(
            'resize',
            cardSettingsStore.RELOAD_TRANSLATE_X,
        );
    }
}
