// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/svg/scroll-btn-icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".map-footer{position:absolute;bottom:30px;box-sizing:border-box}@media (max-width:948px){.map-footer{display:flex;justify-content:space-between;align-items:center;width:100%;padding:0 24px;left:0}}@media (max-width:575px){.map-footer{max-width:100%;padding-left:24px;padding-right:24px;left:50%;transform:translateX(-50%);bottom:17px;flex-direction:column}}.map-footer *{box-sizing:border-box}.status-list{background-color:#fff;border-radius:10px;padding:11px 9px 11px 16px;display:inline-flex;flex-direction:column;width:auto;margin:0}@media (max-width:1080px){.status-list{flex-direction:row;padding:8px 16px;border-radius:30px}}@media (max-width:575px){.status-list{order:1;margin-top:16px}}.status-list__item{display:inline-flex;align-items:center;margin-bottom:10px}.status-list__item:last-child{margin-bottom:0}@media (max-width:1080px){.status-list__item{margin-bottom:0;margin-right:16px}.status-list__item:last-child{margin-right:0}}.status-list__item-circle{width:6px;height:6px;border-radius:50%;margin-right:8px}.scroll-map{display:flex;width:64px;height:32px;background-color:transparent;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-position:50%;background-repeat:no-repeat;cursor:pointer}@media (min-width:949px){.scroll-map{display:none}}@media (max-width:575px){.scroll-map{order:0}}.scroll-map__btn{width:50%;height:100%;background-color:transparent;display:block;outline:unset;border:unset}", ""]);
// Exports
module.exports = exports;
