









































































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
import CustomList from '@/components/commons/CustomList.vue';
import HouseIcon from '@/components/commons/icons/HouseIcon.vue';

const SliderGallery = () => import('@/components/slider/SliderGallery.vue');
const SliderGalleryMobile = () =>
    import('@/components/slider/SliderGalleryMobile.vue');

import { ILandPlot } from '@/types/LandPLot';
import { CustomListItem } from '@/types/common/CustomList';

import { cashSpacer } from '@/helpers/cashHelpers';

@Component({
    components: {
        CustomButton,
        CustomList,
        SliderGallery,
        SliderGalleryMobile,
        HouseIcon,
    },
})
export default class LandPlotModalTop extends Vue {
    @Prop({
        default: () => ({
            id: 1,
            house: {
                id: -1,
                name: '',
                descriptionIn: '',
                descriptionOut: '',
                area: 0,
                floors: 0,
                images: [],
                in: [
                    {
                        id: -1,
                        name: '',
                        value: '',
                    },
                ],
                out: [
                    {
                        id: 1,
                        name: '',
                        value: '',
                    },
                ],
            },
            plot: {
                id: -1,
                area: 0,
                status: 'free',
                number: 0,
                cost: 0,
                description: '',
                facilitiesIdList: {},
                images: [],
                parameters: [],
            },
        }),
    })
    landPlot!: ILandPlot;

    formatedCost(price: number) {
        return cashSpacer(price, ' ');
    }

    get oldPrice() {
        return this.landPlot.plot.discount.showFullPrice
            ? this.landPlot.plot.cost
            : this.landPlot.plot.costOneHundred;
    }

    get currentPrice() {
        if (this.landPlot.plot.discount.showFullPrice) {
            return this.landPlot.plot.newCost || this.landPlot.plot.cost;
        } else {
            return (
                this.landPlot.plot.newCostOneHundred ||
                this.landPlot.plot.cost
            );
        }
    }

    get infoPriceTitle() {
        return this.landPlot.plot.discount.showFullPrice
            ? 'Цена'
            : 'Цена за сотку';
    }

    get cadastralNumber() {
        return this.landPlot.plot.cadastralNumber;
    }

    get listTop(): Array<CustomListItem> {
        const result = [
            {
                id: -1,
                title: 'Площадь участка,  соток',
                text: this.landPlot.plot.area
                    ? this.landPlot.plot.area.toString()
                    : '',
            },
        ];

        if (this.landPlot.house)
            this.landPlot.house.out.forEach(i =>
                result.push({
                    id: i.id,
                    title: i.name,
                    text: i.value,
                }),
            );
        if (
            this.landPlot &&
            this.landPlot.plot &&
            this.landPlot.plot.parameters &&
            this.landPlot.plot.parameters.length
        )
            this.landPlot.plot.parameters.forEach(param => {
                if (param.name === 'Срок сдачи' && param.value) {
                    result.push({
                        id: param.id || 0,
                        title: param.name,
                        text: param.value,
                    });
                }
            });

        return result;
    }
    get landPlotTitle() {
        const separator = this.landPlot.plot.street.name ? ', ' : '';
        return (
            this.landPlot.plot.name +
            separator +
            this.landPlot.plot.street.name +
            ' ' +
            this.landPlot.plot.houseNumber
        );
    }

    get discountLink() {
        if (
            !this.landPlot.plot.action &&
            !this.landPlot.plot.discount.value
        )
            return '';
        const isСrutchPlot = [166, 167, 168].indexOf(
            +this.landPlot.plot.number,
        );

        return isСrutchPlot
            ? 'a[href="#popup:news4"]'
            : '.show-modal-promotion';
    }

    showModalFromTilda(selector: string) {
        const btn: HTMLElement | null = document.querySelector(selector);
        if (!btn) return;
        btn.click();
        this.$emit('close');
    }
    openTildaModalSignUpForViewing() {
        const btn: HTMLElement | null = document.querySelector(
            '.sign-up-for-viewing',
        )
            ? document.querySelector('.sign-up-for-viewing a')
            : null;
        if (!btn) {
            console.error('Не удалось найти кнопку .sign-up-for-viewing a');
            return;
        }
        btn.click();
        const id = this.landPlot.plot.number
            ? this.landPlot.plot.number
            : null;
        if (!id) {
            console.error(
                'ID участка не был найден, проверьте верность заполнения участка в админке',
            );
            return;
        }
        const input: HTMLInputElement | null = document.querySelector(
            '.sign-up-for-viewing__plot-filed',
        )
            ? document.querySelector(
                  '.sign-up-for-viewing__plot-filed input[type="hidden"]',
              )
            : null;
        if (!input) {
            console.error('Неудалось найти инпут');
            return;
        }
        input.value = String(id);
        this.$emit('close');
    }

    openTildaModalAskQuestion() {
        const btn: HTMLElement | null = document.querySelector(
            '.ask-a-question',
        )
            ? document.querySelector('.ask-a-question a')
            : null;
        if (!btn) {
            console.error('Не удалось найти кнопку .ask-a-question a');
            return;
        }
        btn.click();
        const id = this.landPlot.plot.number
            ? this.landPlot.plot.number
            : null;
        if (!id) {
            console.error(
                'ID участка не был найден, проверьте верность заполнения участка в админке',
            );
            return;
        }
        const input: HTMLInputElement | null = document.querySelector(
            '.ask-a-question__field',
        )
            ? document.querySelector(
                  '.ask-a-question__field input[type="hidden"]',
              )
            : null;
        if (!input) {
            console.error('Неудалось найти инпут');
            return;
        }
        input.value = String(id);
        this.$emit('close');
    }

    openInFullScreen(data: number) {
        this.$emit('openInFullScreenPlotModalGallery', data);
    }

    openCadastralPage() {
        if (!this.cadastralNumber) return;
        const text = this.cadastralNumber.replace(/:/g, '%3A');
        const opened = this.cadastralNumber
            .split(':')
            .map(item => {
                if (item.startsWith('0')) item = item.substring(1);
                return item;
            })
            .join('%3A');
        const url = `https://pkk.rosreestr.ru/#/search/?text=${text}&type=1&opened=${opened}&inPoint=true`;

        window.open(url, '_blank');
    }
}
