






import { Component, Vue } from 'vue-property-decorator';
@Component
export default class CardTemplate extends Vue {}
