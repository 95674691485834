























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import { cashSpacer } from '@/helpers/cashHelpers';
import { debounce, throttle } from 'lodash';

@Component({
    components: {},
})
export default class CustomRangeSelect extends Vue {
    @Prop({ default: 'lg' }) size!: string;
    @Prop({ default: 0 }) min!: number;
    @Prop({ default: 100000 }) max!: number;
    @Prop() value!: { min: number; max: number };

    @Prop({ default: 16 }) thumbWidth!: number;
    @Prop({ default: 16 }) thumbHeight!: number;

    minValue = this.min;
    maxValue = this.max;
    // текущие значения для вывода
    get formatedValues() {
        return {
            min: cashSpacer(this.minValue, ' '),
            max: cashSpacer(this.maxValue, ' '),
        };
    }
    // DomRect тумб и рельсы
    railDomRect = new DOMRect(0, 0, 0, 0);
    railInnerDomRect = new DOMRect(0, 0, 0, 0);
    minThumbDomRect = new DOMRect(0, 0, 0, 0);
    maxThumbDomRect = new DOMRect(0, 0, 0, 0);
    // Передвигается тумба или нется
    isMinThumbDragging = false;
    isMaxThumbDragging = false;
    // Смещение тумб от левого края ползунка (в пикселях)
    minThumbMovement = 0;
    maxThumbMovement = 0;

    calculateElementsPositions() {
        // Рельса
        if (this.$refs.rail instanceof HTMLElement)
            this.railDomRect = this.$refs.rail.getBoundingClientRect();
        else
            console.error(
                'Rail is not defined. /commons/CustomInputs/CustomRangeSelect.vue',
            );
        // левая тумба
        if (this.$refs.minValueThumb instanceof HTMLElement)
            this.minThumbDomRect =
                this.$refs.minValueThumb.getBoundingClientRect();
        else
            console.error(
                'minValueThumb is not defined. /commons/CustomInputs/CustomRangeSelect.vue',
            );
        // правая тумба
        if (this.$refs.maxValueThumb instanceof HTMLElement)
            this.maxThumbDomRect =
                this.$refs.maxValueThumb.getBoundingClientRect();
        else
            console.error(
                'maxValueThumb is not defined. /commons/CustomInputs/CustomRangeSelect.vue',
            );
    }

    onPointerDownOnThumb(e: MouseEvent | TouchEvent, thumb: 'min' | 'max') {
        if (thumb === 'min') this.isMinThumbDragging = true;
        else if (thumb === 'max') this.isMaxThumbDragging = true;
        this.calculateElementsPositions();
    }

    onPointerMove(e: MouseEvent | TouchEvent) {
        if (!this.isMinThumbDragging && !this.isMaxThumbDragging) return;

        const clientX =
            e instanceof MouseEvent ? e.clientX : e.touches[0].clientX;

        if (this.isMinThumbDragging) {
            this.minThumbMovement =
                Math.min(
                    Math.max(this.railDomRect.left, clientX),
                    this.maxThumbDomRect.left,
                ) - this.railDomRect.left;

            if (
                this.minThumbMovement + this.thumbWidth >=
                this.maxThumbMovement
            )
                this.minThumbMovement =
                    this.maxThumbMovement - this.thumbWidth;
            this.minValue =
                Math.floor(
                    ((this.max - this.min) *
                        (this.minThumbMovement /
                            (this.railDomRect.right -
                                this.railDomRect.left)) +
                        this.min) *
                        100,
                ) / 100;
        } else if (this.isMaxThumbDragging) {
            this.maxThumbMovement =
                Math.max(
                    Math.min(this.railDomRect.right, clientX),
                    this.minThumbDomRect.right,
                ) - this.railDomRect.left;
            if (
                this.maxThumbMovement <=
                this.minThumbMovement + this.thumbWidth
            )
                this.maxThumbMovement =
                    this.minThumbMovement + this.thumbWidth;
            this.maxValue =
                Math.floor(
                    ((this.max - this.min) *
                        (this.maxThumbMovement /
                            (this.railDomRect.right -
                                this.railDomRect.left)) +
                        this.min) *
                        100,
                ) / 100;
        }
        this.$emit('change', { min: this.minValue, max: this.maxValue });
        this.$emit('input', { min: this.minValue, max: this.maxValue });
    }

    onPointerUp(e: MouseEvent | TouchEvent) {
        this.isMinThumbDragging = false;
        this.isMaxThumbDragging = false;
    }

    updateTranslateSizes() {
        const WIDTH = this.railDomRect.width;
        //
        const thumbFrom = this.value.min;
        const min = this.min;
        const thumbTo = this.value.max;
        const Max = this.max;
        //
        let percentTranslateXmax = (100 * (thumbTo - min)) / (Max - min);
        let pxTranslateXmax = (percentTranslateXmax * WIDTH) / 100;
        //
        let percentTranslateXmin = (100 * (thumbFrom - min)) / (Max - min);
        let pxTranslateXmin = (percentTranslateXmin * WIDTH) / 100;
        //
        this.maxThumbMovement = pxTranslateXmax;
        this.minThumbMovement = pxTranslateXmin;
        this.minValue = thumbFrom;
        this.maxValue = thumbTo;
    }

    @Watch('min')
    on() {
        this.minValue = this.min;
    }

    @Watch('max')
    on2() {
        this.maxValue = this.max;
    }

    @Watch('value')
    onChange() {
        if (this.value.min && this.value.min !== this.minValue)
            this.minValue = this.value.min;
        if (this.value.max && this.value.max !== this.maxValue)
            this.maxValue = this.value.max;

        this.calculateElementsPositions();
        if (this.value.min === this.min) {
            this.minThumbMovement = 0;
            this.minValue = this.value.min;
        }

        if (
            this.value.max === this.max &&
            this.isMaxThumbDragging !== true
        ) {
            this.maxValue = this.value.max;
            this.maxThumbMovement =
                this.railDomRect.right - this.railDomRect.left;
        }
    }

    mounted() {
        // Вешаем события на тумбы
        // зажим
        if (this.$refs.minValueThumb instanceof HTMLElement) {
            this.$refs.minValueThumb.addEventListener('mousedown', e =>
                this.onPointerDownOnThumb(e, 'min'),
            );
            this.$refs.minValueThumb.addEventListener('touchstart', e =>
                this.onPointerDownOnThumb(e, 'min'),
            );
        } else
            console.error(
                'minValueThumb is not defined. /commons/CustomInputs/CustomRangeSelect.vue',
            );
        if (this.$refs.maxValueThumb instanceof HTMLElement) {
            this.$refs.maxValueThumb.addEventListener('mousedown', e =>
                this.onPointerDownOnThumb(e, 'max'),
            );
            this.$refs.maxValueThumb.addEventListener('touchstart', e =>
                this.onPointerDownOnThumb(e, 'max'),
            );
        } else
            console.error(
                'maxValueThumb is not defined. /commons/CustomInputs/CustomRangeSelect.vue',
            );
        // перемещение
        window.addEventListener('mousemove', e =>
            debounce(this.onPointerMove, 10)(e),
        );
        window.addEventListener('touchmove', e =>
            debounce(this.onPointerMove, 10)(e),
        );
        // отжим
        window.addEventListener('mouseup', e => this.onPointerUp(e));
        window.addEventListener('touchend', e => this.onPointerUp(e));
        // Смена разрешения экрана
        window.addEventListener('resize', () => {
            // Перерасчет положений тумб при резайзе экрана
            setTimeout(() => {
                this.calculateElementsPositions();
                this.updateTranslateSizes();
            }, 300);
        });
        setTimeout(() => {
            // высчитываем позиции
            this.calculateElementsPositions();
            this.minThumbMovement = 0;
            this.maxThumbMovement =
                this.railDomRect.right - this.railDomRect.left;
        });

        //
        setTimeout(() => {
            this.updateTranslateSizes();
        }, 200);

        //
    }

    beforeDestroy() {
        if (this.$refs.minValueThumb instanceof HTMLElement) {
            this.$refs.minValueThumb.removeEventListener('mousedown', e =>
                this.onPointerDownOnThumb(e, 'min'),
            );
            this.$refs.minValueThumb.removeEventListener('touchstart', e =>
                this.onPointerDownOnThumb(e, 'min'),
            );
        }
        if (this.$refs.maxValueThumb instanceof HTMLElement) {
            this.$refs.maxValueThumb.removeEventListener('mousedown', e =>
                this.onPointerDownOnThumb(e, 'max'),
            );
            this.$refs.maxValueThumb.removeEventListener('touchstart', e =>
                this.onPointerDownOnThumb(e, 'max'),
            );
        }
        // перемещение
        window.removeEventListener('mousemove', e =>
            debounce(this.onPointerMove, 10)(e),
        );
        window.removeEventListener('touchmove', e =>
            debounce(this.onPointerMove, 10)(e),
        );
        // отжим
        window.removeEventListener('mouseup', e => this.onPointerUp(e));
        window.removeEventListener('touchend', e => this.onPointerUp(e));
        // Смена разрешения экрана
        window.removeEventListener('resize', () => {
            setTimeout(() => {
                this.calculateElementsPositions();
            });
        });
    }
}
