// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/CardHouse/treangle.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".card-house__triangle{position:absolute;bottom:-5px;left:50%;transform:translateX(-50%);width:28px;height:8px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:top;background-size:100%;background-repeat:no-repeat;transition:all .3s ease;z-index:100}.card-house__header{background-position:50%;background-size:cover;background-repeat:no-repeat;border-radius:10px;width:100%;height:160px;padding:14px;margin-bottom:16px}.card-house__header-status{padding:5px 8px;height:26px;display:inline-flex;align-items:center;justify-content:center;text-align:center;background-color:#fff;border-radius:10px}.card-house__body-list,.card-house__body-title{margin-bottom:14px}.card-house__body-list-item{display:flex;justify-content:space-between;align-items:center;padding:13px 16px;border-radius:10px;background-color:#f6f3ea;margin-bottom:3px}.card-house__body-list-item:last-child{margin-bottom:0}.card-house__body-price{display:flex;justify-content:space-between;align-items:center;padding:0 16px 14px 16px}.card-house.card-plot{width:229px;transform:translateY(0) translateX(-50%)}", ""]);
// Exports
module.exports = exports;
