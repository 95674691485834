

























import { Component, Vue } from 'vue-property-decorator';
import FilterIcon from '@/components/commons/icons/FilterIcon.vue';
import { landPlotStore } from '@/store/modules/LandPLot';
import { cardSettingsStore } from '@/store/modules/CardSettings';

@Component({
    components: { FilterIcon },
})
export default class MapFooter extends Vue {
    statusList: Array<{
        id: number;
        text: string;
        status: string;
        color: string;
    }> = [
        {
            id: 0,
            text: 'Свободен',
            status: 'success',
            color: '#4D822C',
        },
        {
            id: 1,
            text: 'Забронирован',
            status: 'warning',
            color: '#E9C442',
        },
        {
            id: 2,
            text: 'Занят',
            status: 'danger',
            color: '#C04B20',
        },
    ];

    get isShowInfoForDetailSection() {
        return landPlotStore.CURRENT_SECTION_ZONE ? true : false;
    }

    get translateX() {
        return cardSettingsStore.TRANSLATE_X;
    }

    scrollToLeft() {
        cardSettingsStore.SET_TRANSLATE_X(100);
    }

    scrollToRight() {
        cardSettingsStore.SET_TRANSLATE_X(-100);
    }
}
