






































































































import { Component, Vue } from 'vue-property-decorator';
import { landPlotStore } from '@/store/modules/LandPLot';
import { throttle } from 'lodash';

import Northwest from './map-sections/Northwest.vue';
import Northeast from './map-sections/Northeast.vue';
import Southwest from './map-sections/Southwest.vue';
import Southeast from './map-sections/Southeast.vue';
import SectionRelaxation from './map-sections/SectionRelaxation.vue';

import NorthwestDetail from './map-detail/NorthwestDetail.vue';
import NortheastDetail from './map-detail/NortheastDetail.vue';
import SoutheastDetail from './map-detail/SoutheastDetail.vue';
import SouthwestDetail from './map-detail/SouthwestDetail.vue';

import { MixinMapScroll } from '@/mixins/MixinMapScroll';
import { cardSettingsStore } from '@/store/modules/CardSettings';

import ZoneChangeIcon from '@/components/commons/icons/ZoneChangeIcon.vue';

const zoneDirectionalRelations: any = {
    southwest: {
        top: 'northwest',
        right: 'southeast',
        bottom: null,
        left: null,
    },
    northwest: {
        top: null,
        right: null, //'northeast',
        bottom: 'southwest',
        left: null,
    },
    southeast: {
        top: null, // 'northeast',
        right: null,
        bottom: null,
        left: 'southwest',
    },
    northeast: {
        top: null,
        right: null,
        bottom: 'southeast',
        left: 'northwest',
    },
};

@Component({
    components: {
        Northwest,
        Northeast,
        Southwest,
        Southeast,
        SectionRelaxation,
        NorthwestDetail,
        NortheastDetail,
        SoutheastDetail,
        SouthwestDetail,
        ZoneChangeIcon,
    },
    mixins: [MixinMapScroll],
})
export default class CardHouse extends Vue {
    target: null | HTMLElement = null;

    get zone() {
        return landPlotStore.CURRENT_SECTION_ZONE;
    }

    get isShowFullMap() {
        return !landPlotStore.CURRENT_SECTION_ZONE;
    }

    get zoneSwitchBottonsVisibiliy() {
        return {
            top: zoneDirectionalRelations[this.zone]['top'], // блокирует верхние зоны
            right: zoneDirectionalRelations[this.zone]['right'],
            bottom: zoneDirectionalRelations[this.zone]['bottom'],
            left: zoneDirectionalRelations[this.zone]['left'],
        };
    }

    async onClick(data: string) {
        cardSettingsStore.SET_STATUS_CARD_PLOT_ZONE_SHOW(false);
        landPlotStore.SET_CURRENT_SECTION_ZONE(data);
    }

    async showLandPlotCardZone(data: { value: string; e: MouseEvent }) {
        const { value, e } = data;

        if (e.target instanceof Element) {
            const target = e.currentTarget;
            //@ts-ignore
            const sectionTitle = target.querySelector('.section-title');
            const mapRect = cardSettingsStore.MAP_DOM_RECT;

            sectionTitle.style.opacity = '0';
            const dataRect = e.target.getBoundingClientRect();
            const reselutDataRect = {
                bottom: dataRect.bottom,
                height: dataRect.height,
                left: dataRect.left,
                right: dataRect.right,
                top: dataRect.top,
                topMap: mapRect.top,
                width: dataRect.width,
                x: dataRect.x,
                y: dataRect.y - mapRect.y,
            };

            cardSettingsStore.SET_POSITION_CARD_PLOT_ZONE(reselutDataRect);

            cardSettingsStore.SET_STATUS_CARD_PLOT_ZONE_SHOW(true);
            await landPlotStore.CREATE_LAND_PLOT_CARD_ZONE(value);
        }
    }

    scrollToRelaxZone() {
        const element: HTMLElement | null = document.querySelector(
            '[data-js-scroll="relax-zone"]',
        );
        if (!element) {
            console.error('элемент data-js-scroll="relax-zone" не найден');
            return;
        }
        const sctollTo = element.offsetTop;
        window.scrollTo({
            top: sctollTo,
            behavior: 'smooth',
        });
        element.click();
    }

    mouseOutHandler(e: MouseEvent) {
        if (e.target instanceof Element) {
            const target = e.currentTarget;
            //@ts-ignore
            const sectionTitle = target.querySelector('.section-title');
            sectionTitle.style.opacity = '1';
            cardSettingsStore.SET_STATUS_CARD_PLOT_ZONE_SHOW(false);
        }
    }

    throttleOver(data: { value: string; e: MouseEvent }) {
        throttle(this.showLandPlotCardZone, 1000)(data);
    }
    throttleOut(e: any) {
        throttle(this.mouseOutHandler, 1000)(e);
    }

    showCardRelaxationZone(data: { value: string; e: MouseEvent }) {
        const { value, e } = data;
        if (e.target instanceof Element) {
            const target = e.currentTarget;
            //@ts-ignore
            const sectionTitle = target.querySelector('.section-title');
            sectionTitle.style.opacity = '0';
            const mapRect = cardSettingsStore.MAP_DOM_RECT;
            sectionTitle.style.opacity = '0';
            const dataRect = e.target.getBoundingClientRect();
            const reselutDataRect = {
                bottom: dataRect.bottom,
                height: dataRect.height,
                left: dataRect.left,
                right: dataRect.right,
                top: dataRect.top,
                topMap: mapRect.top,
                width: dataRect.width,
                x: dataRect.x,
                y: dataRect.y - mapRect.y,
            };
            cardSettingsStore.SET_POSITION_CARD_RELAXATION_ZONE(
                reselutDataRect,
            );

            cardSettingsStore.SET_STATUS_CARD_RELAXATION_ZONE_SHOW(true);
        }
    }

    hideCardRelaxationZone(e: MouseEvent) {
        if (e.target instanceof Element) {
            const target = e.currentTarget;
            //@ts-ignore
            const sectionTitle = target.querySelector('.section-title');
            sectionTitle.style.opacity = '1';
            cardSettingsStore.SET_STATUS_CARD_RELAXATION_ZONE_SHOW(false);
        }
    }

    resetFilter() {
        landPlotStore.ZONE_RESET_LAND_PLOT_FILTERS();
        landPlotStore.SET_FILTER_STATUS(false);
    }

    onSwitchZone(switchDirection: 'top' | 'right' | 'bottom' | 'left') {
        if (!zoneDirectionalRelations[this.zone][switchDirection]) return;
        this.resetFilter();
        landPlotStore.SET_CURRENT_SECTION_ZONE(
            zoneDirectionalRelations[this.zone][switchDirection],
        );
    }
}
