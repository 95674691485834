













import { Component, Prop, Vue } from 'vue-property-decorator';
import BackIcon from '@/components/commons/icons/BackIcon.vue';
@Component({
    components: { BackIcon },
})
export default class CustomButtonBack extends Vue {
    @Prop({ default: false }) darkMode!: boolean;
    get color() {
        return this.darkMode ? '#ffffff' : '#000000';
    }
}
