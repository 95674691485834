import {
    Module,
    VuexModule,
    Mutation,
    Action,
    getModule,
} from 'vuex-module-decorators';

import store from '@/store';

import { getRoute } from '@/helpers/routeHelpers';
import { removeDuplicates } from '@/helpers/removeDuplicates';
import {
    IIncomingLandPlot,
    ILandPlot,
    ILandPlotFilter,
    PlotStatus,
    ILandPlotCardMini,
    ILandPlotCardZone,
    IRectSizes,
    IHiddenFIlters,
} from '@/types/LandPLot';
import { indexOf, reject } from 'lodash';
import { IFacility } from '@/types/Facility';

const landPlotDataZoneFilter = async (
    list: ILandPlot[],
    zone: string | null = null,
): Promise<ILandPlot[]> => {
    return new Promise((resolve, reject) => {
        let listSection: ILandPlot[];
        if (zone) {
            listSection = list.filter(item => {
                if (item.plot.zone && item.plot.zone === zone) return item;
            });
        } else listSection = list;
        resolve(listSection);
    });
};

const zoneLandPlotDataZoneHandler = async (
    list: ILandPlot[],
): Promise<{ [index: string]: ILandPlot[] }> => {
    return new Promise(async (resolve, reject) => {
        const NW = await landPlotDataZoneFilter(list, 'northwest');
        const NE = await landPlotDataZoneFilter(list, 'northeast');
        const SW = await landPlotDataZoneFilter(list, 'southwest');
        const SE = await landPlotDataZoneFilter(list, 'southeast');

        resolve({
            northwest: NW,
            northeast: NE,
            southwest: SW,
            southeast: SE,
        });
    });
};

const landPlotDataHandler = async (
    data: IIncomingLandPlot[],
): Promise<{
    main: ILandPlot[];
    price: { min: number; max: number };
    plotArea: { min: number; max: number };
    houseArea: { min: number; max: number };
    floors: number[];
    facalities: IFacility[];
}> => {
    return new Promise((resolve, reject) => {
        let minPrice = Infinity;
        let maxPrice = -Infinity;
        //
        let minPlotArea = Infinity;
        let maxPlotArea = -Infinity;
        //
        let minHouseArea = Infinity;
        let maxHouseArea = -Infinity;
        //

        const main = data.map((item: IIncomingLandPlot) => {
            minPrice = Math.min(minPrice, item.plot_info.cost);
            maxPrice = Math.max(maxPrice, item.plot_info.cost);
            //
            minPlotArea = Math.min(minPlotArea, item.plot_info.area);
            maxPlotArea = Math.max(maxPlotArea, item.plot_info.area);
            //
            if (item.house_info) {
                minHouseArea = Math.min(minHouseArea, item.house_info.area);
                maxHouseArea = Math.max(maxHouseArea, item.house_info.area);
            }

            return {
                id: item.id,
                house: item.house_info
                    ? {
                          id: item.house_info.id,
                          name: item.house_info.name,
                          descriptionIn: item.house_info.description_in,
                          descriptionOut: item.house_info.description_out,
                          area: item.house_info.area,
                          floors: item.house_info.floors,
                          images: item.house_info.images
                              .sort((a, b) => a.sort - b.sort)
                              .map(item => item.URL),
                          in: item.house_info.in,
                          out: item.house_info.out,
                      }
                    : null,
                plot: {
                    id: item.plot_info.id,
                    name: item.plot_info.name,
                    area: item.plot_info.area,
                    houseNumber: item.plot_info.house_number,
                    street: item.plot_info.street,
                    status: item.plot_info.status,
                    number: item.plot_info.number,
                    cost: item.plot_info.cost,
                    newCost: item.plot_info.new_cost,
                    costOneHundred: item.plot_info.cost_one_hundred,
                    newCostOneHundred: item.plot_info.new_cost_one_hundred,
                    description: item.plot_info.description,
                    facilitiesIdList: item.plot_info.facilities.map(
                        item => item.id,
                    ),
                    images: item.plot_info.images
                        .sort((a, b) => a.sort - b.sort)
                        .map(item => item.URL),
                    parameters: item.plot_info.parameters,
                    zone: item.plot_info.zone,
                    action: item.plot_info.action,
                    additional: item.plot_info.additional,
                    cadastralNumber: item.plot_info.cadastral_number,
                    // discount:
                    //     item &&
                    //     item.plot_info.action &&
                    //     [163, 164, 165].indexOf(+item.plot_info.number) + 1
                    //         ? {
                    //               ...item.plot_info.discount,
                    //               price: 24900,
                    //               showFullPrice: false,
                    //           }
                    //         : {
                    //               ...item.plot_info.discount,
                    //               price: 0,
                    //               showFullPrice: true,
                    //           },
                    discount: {
                        value: item.plot_info.discount.value,
                        description: item.plot_info.discount.description,
                        showFullPrice: item.plot_info.discount.show_full_price,
                    },
                },
            };
        });
        //
        const florsSet = new Set();
        const facalitiesList: IFacility[] = [];
        //
        main.forEach(item => {
            if (!item.house) return false;
            florsSet.add(item.house.floors);
        });
        data.forEach(item => {
            item.plot_info.facilities.forEach(el => {
                facalitiesList.push(el);
            });
        });
        //
        //@ts-ignore
        const floors: number[] = Array.from(florsSet).sort(
            //@ts-ignore
            (a: number, b: number) => a - b,
        );
        //
        const facalities = removeDuplicates(facalitiesList);
        //
        resolve({
            price: {
                min: minPrice,
                max: maxPrice,
            },
            plotArea: {
                min: minPlotArea,
                max: maxPlotArea,
            },
            houseArea: {
                min: minHouseArea,
                max: maxHouseArea,
            },
            main: main,
            floors: floors,
            facalities: facalities,
        });
    });
};

const landPlotDataFilter = async (
    list: ILandPlot[],
    filter: ILandPlotFilter,
    zone: string | null = null,
): Promise<ILandPlot[]> => {
    return new Promise(async (resolve, reject) => {
        const result = list.filter(
            item =>
                // Проврка на наличие / отсутсвие дома
                ((item.house && filter.withHouse && !filter.withoutHouse) ||
                    (!item.house && filter.withoutHouse && !filter.withHouse) ||
                    filter.withHouse === filter.withoutHouse) &&
                // Проверка статуса участка
                (filter.status.length === 0 ||
                    !!(indexOf(filter.status, item.plot.status) + 1)) &&
                // Проверка на соответсвие площади дома
                (!item.house ||
                    (item.house.area >= filter.houseAreaMin &&
                        item.house.area <= filter.houseAreaMax)) &&
                // Проверка на соответсвие площади участка
                item.plot.area >= filter.landPLotAreaMin &&
                item.plot.area <= filter.landPLotAreaMax &&
                // Проверка на соответсвие цены
                item.plot.cost >= filter.landPLotPriceMin &&
                item.plot.cost <= filter.landPLotPriceMax &&
                // Проверка на колчиество этажей
                (!item.house ||
                    filter.allowedFloors.length === 0 ||
                    !!(indexOf(filter.allowedFloors, item.house.floors) + 1)) &&
                // Проверка на удобства
                (() => {
                    if (
                        Object.keys(filter.facilities).length &&
                        !item.plot.facilitiesIdList.some(
                            facilitiesFilter =>
                                filter.facilities[facilitiesFilter],
                        )
                    )
                        return false;
                    return true;
                })(),
        );
        resolve(result);
    });
};

const landPlotDataCardZone = async (
    list: Array<ILandPlot>,
    zone: string,
): Promise<ILandPlotCardZone> => {
    return new Promise((resolve, reject) => {
        const response = ((list: Array<ILandPlot>) => {
            const findedItem =
                list.find(item => {
                    if (item.plot.zone === zone) {
                        return item;
                    }
                }) || ({} as ILandPlot);
            let title: string = '';
            let withHouse: boolean;
            if (zone === 'northwest' || zone === 'southeast') {
                title = 'Зона домов';
                withHouse = true;
            } else {
                title = 'Зона участков';
                withHouse = false;
            }

            const paramsCount = list.filter(item => {
                if (item.plot.zone === zone) return item;
            });
            const paramsIsFreeCount = paramsCount.filter(item => {
                if (item.plot.status === 'free') return item;
            });
            const params = {
                count: paramsCount.length,
                isFreeCount: paramsIsFreeCount.length,
            };
            return findedItem && Object.keys(findedItem).length
                ? {
                      id: zone,
                      imgUrl: findedItem.house
                          ? findedItem.house.images[0]
                          : findedItem.plot.images[0],
                      title: title,
                      params: params,
                      withHouse,
                  }
                : ({} as ILandPlotCardZone);
        })(list);

        resolve(response);
    });
};

const landPlotDataCardMini = async (
    list: Array<ILandPlot>,
    id: string,
): Promise<ILandPlotCardMini> => {
    return new Promise((resolve, reject) => {
        const filterList = ((list: Array<ILandPlot>) => {
            return list.find(item => {
                if (item.plot.street && item.plot.number === +id) return item;
            });
        })(list);

        let result = filterList;

        const rentDate =
            result && result.house
                ? result.house.out.find(item => {
                      if (item.name.toLowerCase() == 'срок сдачи') return item;
                  })
                : null;

        const plotParametres = result ? result.plot.parameters : '';
        const houseOut =
            Array.isArray(plotParametres) && plotParametres.length
                ? plotParametres[0].value
                : '';

        let resultObject: ILandPlotCardMini =
            result && id && filterList && Object.keys(filterList).length
                ? {
                      id:
                          result.plot.street && result.plot.number
                              ? String(result.plot.number)
                              : '',
                      withHouse: result.house ? true : false,
                      imgUrl: result.plot.images[0],
                      status: result.plot.status,
                      title: result.house
                          ? result.house.name
                          : result.plot.name,
                      params: {
                          areaHouse: result.house ? result.house.area : '',
                          areaPlot: result.plot.area,
                          streetName: result.plot.street.name,
                          streetNumber: result.plot.houseNumber,
                          houseOut,
                      },
                      rentDate: rentDate,
                      price: result.plot.cost,
                      newPrice: result.plot.newCost,
                      costOneHundred: result.plot.costOneHundred,
                      newCostOneHundred: result.plot.newCostOneHundred,
                      discount: result.plot.discount,
                      action: result.plot.action,
                  }
                : ({} as ILandPlotCardMini);
        resolve(resultObject);
    });
};

const landPlotDataCardinModal = async (
    list: Array<ILandPlot>,
    id: number | string,
): Promise<ILandPlot> => {
    return new Promise((resolve, reject) => {
        const result =
            list.find(item => {
                if (item.plot.street && item.plot.number === +id) {
                    return item;
                }
            }) || ({} as ILandPlot);

        resolve(result);
    });
};

export interface ILandPLotStore {
    SET_LAND_PLOTS: (data: ILandPlot[]) => void;
    LOAD_LAND_PLOTS: () => Promise<void>;

    ZONE_RESET_LAND_PLOT_FILTERS: () => void;
    APPLY_ZONE_LAND_PLOT_FILTERS: () => Promise<void>;

    PRICE_GAP: { min: number; max: number };
    HOUSE_AREA_GAP: { min: number; max: number };
    PLOT_AREA_GAP: { min: number; max: number };
    SET_GAPS: (data: {
        price: { min: number; max: number };
        plotArea: { min: number; max: number };
        houseArea: { min: number; max: number };
        floors: number[];
    }) => void;
}

@Module({
    dynamic: true,
    store,
    name: 'landPLotStore',
})
class LandPLot extends VuexModule implements ILandPLotStore {
    currentSectionZone: string = '';

    landPlotCardZone: ILandPlotCardZone = {} as ILandPlotCardZone;
    landPlotCardMini: ILandPlotCardMini = {} as ILandPlotCardMini;
    landPlotCardInModal: ILandPlot = {} as ILandPlot;

    filterStatus: { [index: string]: boolean } = {
        northeast: false,
        northwest: false,
        southeast: false,
        southwest: false,
    };

    facalities: IFacility[] = [];

    private priceGap = {
        min: 0,
        max: 9999999999999,
    };

    private plotAreaGap = {
        min: 0,
        max: 9999999999999,
    };

    private houseAreaGap = {
        min: 0,
        max: 9999999999999,
    };

    private landPLots: ILandPlot[] = [];
    //Скрытие фильтров для каждой зоны
    hiddenFilters: IHiddenFIlters = {
        northeast: {
            landPLotAreaHidden: false,
            withHouseHidden: false,
            houseAreaHidden: false,
        },
        northwest: {
            landPLotAreaHidden: false,
            withHouseHidden: false,
            houseAreaHidden: false,
        },
        southwest: {
            landPLotAreaHidden: false,
            withHouseHidden: true,
            houseAreaHidden: true,
        },
        southeast: {
            landPLotAreaHidden: true,
            withHouseHidden: true,
            houseAreaHidden: false,
        },
    };
    // Разделение фильтров для каждой зоны

    allLandPlots: {
        [index: string]: ILandPlot[];
    } = {
        northeast: [],
        northwest: [],
        southeast: [],
        southwest: [],
    };

    allFilteredLandPlots: {
        [index: string]: ILandPlot[];
    } = {
        northeast: [],
        northwest: [],
        southeast: [],
        southwest: [],
    };
    floors = [] as number[];

    allLandPLotsFilters: {
        [index: string]: ILandPlotFilter;
    } = {
        northeast: {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: 0,
            houseAreaMax: 9999999999999,

            landPLotAreaMin: 0,
            landPLotAreaMax: 9999999999999,

            landPLotPriceMin: 0,
            landPLotPriceMax: 9999999999999,

            allowedFloors: [],
            facilities: {},
        },
        northwest: {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: 0,
            houseAreaMax: 9999999999999,

            landPLotAreaMin: 0,
            landPLotAreaMax: 9999999999999,

            landPLotPriceMin: 0,
            landPLotPriceMax: 9999999999999,

            allowedFloors: [],
            facilities: {},
        },
        southeast: {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: 0,
            houseAreaMax: 9999999999999,

            landPLotAreaMin: 0,
            landPLotAreaMax: 9999999999999,

            landPLotPriceMin: 0,
            landPLotPriceMax: 9999999999999,

            allowedFloors: [],
            facilities: {},
        },
        southwest: {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: 0,
            houseAreaMax: 9999999999999,

            landPLotAreaMin: 0,
            landPLotAreaMax: 9999999999999,

            landPLotPriceMin: 0,
            landPLotPriceMax: 9999999999999,

            allowedFloors: [],
            facilities: {},
        },
    };

    get FILTER_STATUS() {
        return this.filterStatus[this.currentSectionZone];
    }

    get CURRENT_SECTION_ZONE() {
        return this.currentSectionZone;
    }

    get ZONE_LAND_PLOT_FILTERS() {
        return this.allLandPLotsFilters[this.currentSectionZone];
    }

    get ZONE_LAND_PLOT_FILTERS_FLOORS() {
        return this.allLandPLotsFilters[this.currentSectionZone].allowedFloors;
    }

    get CURRENT_FILTERED_LAND_PLOTS() {
        return this.allFilteredLandPlots[this.currentSectionZone];
    }

    get HIDDEN_FILTERS() {
        return this.hiddenFilters[this.currentSectionZone];
    }

    get LAND_PLOT_CARD_ZONE() {
        return this.landPlotCardZone;
    }

    get LAND_PLOT_CARD_MINI() {
        if (this.landPlotCardMini) return this.landPlotCardMini;
    }

    get LAND_PLOT_CARD_IN_MODAL() {
        return this.landPlotCardInModal;
    }

    get PRICE_GAP() {
        return this.priceGap;
    }
    get HOUSE_AREA_GAP() {
        return this.houseAreaGap;
    }
    get PLOT_AREA_GAP() {
        return this.plotAreaGap;
    }

    get FLOORS() {
        return this.floors;
    }

    get FACALITIES() {
        return this.facalities;
    }

    @Mutation
    SET_GAPS_DATA_FILTER(data: {
        price: { min: number; max: number };
        plotArea: { min: number; max: number };
        houseArea: { min: number; max: number };
    }) {
        for (let item in this.allLandPLotsFilters) {
            this.allLandPLotsFilters[item].landPLotPriceMax = data.price.max;
            this.allLandPLotsFilters[item].landPLotPriceMin = data.price.min;
            //
            this.allLandPLotsFilters[item].landPLotAreaMax = data.plotArea.max;
            this.allLandPLotsFilters[item].landPLotAreaMin = data.plotArea.min;
            //
            this.allLandPLotsFilters[item].houseAreaMax = data.houseArea.max;
            this.allLandPLotsFilters[item].houseAreaMin = data.houseArea.min;
        }
    }

    @Mutation
    SET_FILTER_STATUS(status: boolean) {
        this.filterStatus[this.currentSectionZone] = status;
    }

    @Mutation
    SET_CURRENT_SECTION_ZONE(data: string) {
        this.currentSectionZone = data;
    }

    // Формируем списки участков по зонам
    @Mutation
    SET_LAND_PLOTS_ZONES(data: { [index: string]: ILandPlot[] }) {
        this.allLandPlots = data;
        this.allFilteredLandPlots = { ...this.allLandPlots };
    }

    @Mutation
    SET_LAND_PLOTS(data: ILandPlot[]) {
        this.landPLots = data;
    }

    @Mutation
    SET_ZONE_FILTERED_LAND_PLOTS(data: ILandPlot[]) {
        this.allFilteredLandPlots[this.currentSectionZone] = data;
    }

    @Mutation
    SET_LAND_PLOT_CARD_ZONE(data: ILandPlotCardZone) {
        this.landPlotCardZone = data;
    }

    @Mutation
    SET_LAND_PLOT_CARD_MINI(data: ILandPlotCardMini) {
        this.landPlotCardMini = data;
    }

    @Mutation
    SET_LAND_PLOT_CARD_IN_MODAL(data: ILandPlot) {
        this.landPlotCardInModal = data;
    }

    @Mutation
    SET_FLOORS(floors: number[]) {
        this.floors = [...floors];
    }

    @Mutation
    SET_GAPS(data: {
        price: { min: number; max: number };
        plotArea: { min: number; max: number };
        houseArea: { min: number; max: number };
    }) {
        this.priceGap = data.price;
        this.houseAreaGap = data.houseArea;
        this.plotAreaGap = data.plotArea;
    }

    @Mutation
    SET_ZONE_LAND_PLOT_FILTERS(data: Partial<ILandPlotFilter>) {
        this.allLandPLotsFilters[this.currentSectionZone] = {
            ...this.allLandPLotsFilters[this.currentSectionZone],
            ...data,
        };
    }

    @Mutation
    async RESET_ZONE_LAND_PLOT_FILTERS() {
        this.allLandPLotsFilters[this.currentSectionZone] = {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: this.houseAreaGap.min,
            houseAreaMax: this.houseAreaGap.max,

            landPLotAreaMin: this.plotAreaGap.min,
            landPLotAreaMax: this.plotAreaGap.max,

            landPLotPriceMin: this.priceGap.min,
            landPLotPriceMax: this.priceGap.max,

            allowedFloors: [],
            facilities: {},
        };
        this.allFilteredLandPlots[this.currentSectionZone] =
            await landPlotDataZoneFilter(
                this.allLandPlots[this.currentSectionZone],
                this.currentSectionZone,
            );
    }

    @Mutation
    async ZONE_RESET_LAND_PLOT_FILTERS() {
        this.allLandPLotsFilters[this.currentSectionZone] = {
            withHouse: false,
            withoutHouse: false,
            status: [],

            houseAreaMin: this.houseAreaGap.min,
            houseAreaMax: this.houseAreaGap.max,

            landPLotAreaMin: this.plotAreaGap.min,
            landPLotAreaMax: this.plotAreaGap.max,

            landPLotPriceMin: this.priceGap.min,
            landPLotPriceMax: this.priceGap.max,

            allowedFloors: [],
            facilities: {},
        };
        this.allFilteredLandPlots[this.currentSectionZone] =
            this.allLandPlots[this.currentSectionZone];
    }

    @Mutation
    SET_FACALITIES(data: IFacility[]) {
        this.facalities = data;
    }

    @Action
    async LOAD_LAND_PLOTS() {
        try {
            const response = await fetch(getRoute('main', 'plots'));
            if (!response.ok) return console.error(response);
            const result = (await response.json()).result;
            const handledData = await landPlotDataHandler(result);
            if (!result) return console.error(response);
            this.SET_LAND_PLOTS(handledData.main);

            // Записываем сформированные и разбитые на зоны списки участков
            this.SET_LAND_PLOTS_ZONES(
                await zoneLandPlotDataZoneHandler(handledData.main),
            );
            this.SET_GAPS_DATA_FILTER({
                price: handledData.price,
                plotArea: handledData.plotArea,
                houseArea: handledData.houseArea,
            });

            this.SET_GAPS({
                price: handledData.price,
                plotArea: handledData.plotArea,
                houseArea: handledData.houseArea,
            });
            this.SET_FLOORS(handledData.floors);
            this.SET_FACALITIES(handledData.facalities);
        } catch (e) {
            console.error(e);
        }
    }

    @Action
    async APPLY_ZONE_LAND_PLOT_FILTERS() {
        this.SET_ZONE_FILTERED_LAND_PLOTS(
            await landPlotDataFilter(
                this.allLandPlots[this.currentSectionZone],
                this.allLandPLotsFilters[this.currentSectionZone],
            ),
        );
    }

    @Action
    async CREATE_LAND_PLOT_CARD_ZONE(zone: string) {
        this.SET_LAND_PLOT_CARD_ZONE(
            await landPlotDataCardZone(this.landPLots, zone),
        );
    }

    @Action
    async CREATE_LAND_PLOT_CARD_MINI(id: string) {
        this.SET_LAND_PLOT_CARD_MINI(
            await landPlotDataCardMini(this.landPLots, id),
        );
    }

    @Action
    async CREATE_LAND_PLOT_CARD_IN_MODAL(id: number | string) {
        const res = await landPlotDataCardinModal(this.landPLots, id);
        this.SET_LAND_PLOT_CARD_IN_MODAL(res);
        if (!Object.keys(res).length) return false;
        return true;
    }
}

export const landPlotStore = getModule(LandPLot);
