import { IImage } from '@/types/Image';
import { IParameter } from '@/types/Parameter';
import { IFacility } from '@/types/Facility';
import { IHouse, IIncomingHouse } from './House';

export enum PlotStatus {
    free = 'free',
    booked = 'booked',
    occupied = 'occupied',
}

export interface IIncomingLandPlot {
    id: number;
    house_info?: IIncomingHouse;
    plot_info: {
        id: number;
        name: string;
        area: number;
        house_number: string;
        street: {
            id: string;
            name: string;
        };
        status: PlotStatus;
        number: number;
        cost: number;
        new_cost: number | null;
        cost_one_hundred: number;
        new_cost_one_hundred: number | null;
        description: string;
        facilities: IFacility[];
        images: IImage[];
        parameters: IParameter[];
        zone: string;
        action: string | null;
        additional: string | null;
        cadastral_number: string;
        discount: {
            value: number | null;
            description: string | null;
            show_full_price: boolean;
        };
    };
}

export interface ILandPlot {
    id: number;
    house: IHouse | null;
    plot: {
        id: number;
        name: string;
        area: number;
        houseNumber: string;
        street: {
            id: string;
            name: string;
        };
        status: PlotStatus;
        number: number;
        cost: number;
        newCost: number | null;
        costOneHundred: number;
        newCostOneHundred: number | null;
        description: string;
        facilitiesIdList: number[];
        images: string[];
        parameters: IParameter[];
        zone: string;
        action: string | null;
        additional: string | null;
        cadastralNumber: string;
        discount: {
            value: number | null;
            description: string | null;
            showFullPrice: boolean;
        };
    };
}

export interface ILandPlotFilter {
    withHouse: boolean;
    withoutHouse: boolean;
    status: PlotStatus[];

    houseAreaMin: number;
    houseAreaMax: number;

    landPLotAreaMin: number;
    landPLotAreaMax: number;

    landPLotPriceMin: number;
    landPLotPriceMax: number;

    allowedFloors: number[];
    facilities: { [key: string]: number };
}

export interface IHiddenFIlters {
    [index: string]: {
        withHouseHidden: boolean;
        houseAreaHidden: boolean;
        landPLotAreaHidden: boolean;
    };
}

export interface ILandPlotCardMini {
    id: string;
    withHouse: boolean;
    imgUrl: string;
    status: PlotStatus;
    title: string;
    params: {
        areaHouse: number | string;
        areaPlot: number;
        streetName: string;
        streetNumber: string;
        houseOut: string;
    };
    rentDate:
        | {
              id: number;
              name: string;
              sort: number;
              value: string;
          }
        | null
        | undefined;
    price: number;
    newPrice: number | null;
    costOneHundred: number;
    newCostOneHundred: number | null;
    discount: {
        value: number | null;
        description: string | null;
        showFullPrice: boolean;
    };
    action: string | null;
}

export interface ILandPlotCardZone {
    id: string;
    imgUrl: string;
    title: string;
    params: {
        count: number;
        isFreeCount: number;
    };
    withHouse: boolean;
}

export interface IRectSizes {
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    topMap: number;
    width: number;
    x: number;
    y: number;
}
