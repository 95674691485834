






















enum directionAngles {
    top = 0,
    right = 90,
    bottom = 180,
    left = 270,
}

import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
    components: {},
})
export default class ZoneChangeIcon extends Vue {
    @Prop({ default: 'top' }) direction!: directionAngles;

    get directionStyles() {
        return `transform: rotate(${directionAngles[this.direction]}deg)`;
    }
}
