











































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { MixinDetailSection } from '@/mixins/MixinDetailSection';
import { MixinMapScroll } from '@/mixins/MixinMapScroll';

@Component({ components: {}, mixins: [MixinDetailSection, MixinMapScroll] })
export default class SoutheastDetail extends Vue {}
