import Vue from 'vue';
import Vuex from 'vuex';

import { IFacilityStore } from '@/store/modules/Facility';
import { ILandPLotStore } from '@/store/modules/LandPLot';
import { ICardSetingsStore } from '@/types/CardSettings';


Vue.use(Vuex);

export interface IRootState {
    facilityStore: IFacilityStore;
    landPLotStore: ILandPLotStore;
    ICardSetingsStore: ICardSetingsStore;
}

export default new Vuex.Store<IRootState>({});
