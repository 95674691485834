import { render, staticRenderFns } from "./CustomCheckbox.vue?vue&type=template&id=2d376c50&"
import script from "./CustomCheckbox.vue?vue&type=script&lang=ts&"
export * from "./CustomCheckbox.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./CustomCheckbox.vue?vue&type=style&index=0&lang=less&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports