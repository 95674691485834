import {
    Module,
    VuexModule,
    Mutation,
    getModule,
} from 'vuex-module-decorators';

import { IRectSizes } from '@/types/LandPLot';

import store from '@/store';
import { ICardSetingsStore, IScrollData } from '@/types/CardSettings';

@Module({
    dynamic: true,
    store,
    name: 'cardSettingsStore',
})
class CardSettings extends VuexModule implements ICardSetingsStore {
    positionCardHouse: IRectSizes = {} as IRectSizes;
    positionCardPlotZone: IRectSizes = {} as IRectSizes;
    positionCardRelaxationZone: IRectSizes = {} as IRectSizes;
    statusCardHouseShow = false;
    statusCardPlotZoneShow = false;
    statusCardRelaxationZoneShow = false;
    isShowlandPlotCardInModal = false;

    //
    scrollDataObject: IScrollData = {
        mapWrapper: null,
        mapWrapperItem: null,
        z: 0,
        vw: 0,
        Xmax: 0,
    };
    //
    mapDomRect: DOMRect = new DOMRect();
    //
    translateX: number = 0;

    get TRANSLATE_CENTER() {
        return this.scrollDataObject.z && this.scrollDataObject.vw
            ? this.scrollDataObject.z / 2 - this.scrollDataObject.vw / 2
            : 0;
    }
    //
    get TRANSLATE_X() {
        return this.translateX;
    }
    //
    get MAP_DOM_RECT() {
        return this.mapDomRect;
    }
    //
    get POSITION_CARD_HOUSE() {
        return this.positionCardHouse;
    }
    //
    get POSITION_CARD_PLOT_ZONE() {
        return this.positionCardPlotZone;
    }
    //
    get STATUS_CARD_HOUSE_SHOW() {
        return this.statusCardHouseShow;
    }
    //
    get STATUS_CARD_PLOT_ZONE_SHOW() {
        return this.statusCardPlotZoneShow;
    }
    //
    get STATUS_CARD_RELAXATION_ZONE_SHOW() {
        return this.statusCardRelaxationZoneShow;
    }
    //
    get IS_SHOW_LAND_PLOT_CARD_IN_MODAL() {
        return this.isShowlandPlotCardInModal;
    }
    //
    // --
    //
    @Mutation
    RELOAD_TRANSLATE_X() {
        const center =
            this.scrollDataObject.z && this.scrollDataObject.vw
                ? this.scrollDataObject.z / 2 - this.scrollDataObject.vw / 2
                : 0;
        this.translateX = center;
    }

    @Mutation
    SET_TRANSLATE_X(data: number) {
        const center =
            this.scrollDataObject.z && this.scrollDataObject.vw
                ? this.scrollDataObject.z / 2 - this.scrollDataObject.vw / 2
                : 0;

        if (this.translateX === 0) {
            if (center + data < this.scrollDataObject.Xmax)
                this.translateX = this.scrollDataObject.Xmax;
            else if (center + data > 0) this.translateX = -1;
            else this.translateX = center + data;
        } else if (this.translateX + data > 0) this.translateX = -1;
        else if (this.translateX + data < this.scrollDataObject.Xmax)
            this.translateX = this.scrollDataObject.Xmax;
        else this.translateX += data;
    }

    @Mutation
    SET_DATA(data: IScrollData) {
        this.scrollDataObject = { ...this.scrollDataObject, ...data };
    }
    //
    @Mutation
    SET_MAP_DOM_RECT(data: DOMRect) {
        this.mapDomRect = data;
    }
    //
    @Mutation
    SET_POSITION_CARD_HOUSE(data: IRectSizes) {
        this.positionCardHouse = data;
    }

    @Mutation
    SET_POSITION_CARD_PLOT_ZONE(data: IRectSizes) {
        this.positionCardPlotZone = data;
    }

    @Mutation
    SET_POSITION_CARD_RELAXATION_ZONE(data: IRectSizes) {
        this.positionCardRelaxationZone = data;
    }

    @Mutation
    SET_STATUS_CARD_HOUSE_SHOW(data: boolean) {
        this.statusCardHouseShow = data;
    }

    @Mutation
    SET_STATUS_CARD_PLOT_ZONE_SHOW(data: boolean) {
        this.statusCardPlotZoneShow = data;
    }

    @Mutation
    SET_STATUS_CARD_RELAXATION_ZONE_SHOW(data: boolean) {
        this.statusCardRelaxationZoneShow = data;
    }
    @Mutation
    LAND_PLOT_CARD_IN_MODAL_SET_SHOW_STATUS(data: boolean) {
        this.isShowlandPlotCardInModal = data;
    }
}

export const cardSettingsStore = getModule(CardSettings);
