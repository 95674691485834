import { Component, Vue, Watch } from 'vue-property-decorator';
import { throttle } from 'lodash';
import { landPlotStore } from '@/store/modules/LandPLot';
import { cardSettingsStore } from '@/store/modules/CardSettings';
enum StatusTheme {
    free = '#9ebf68',
    booked = '#e9c442',
    occupied = '#db6033',
}
@Component
export class MixinDetailSection extends Vue {
    svgGroup: null | HTMLElement = null;

    get filteredPlotHouseList() {
        return landPlotStore.CURRENT_FILTERED_LAND_PLOTS;
    }

    async resetThemeStyle() {
        let pathGroup = this.svgGroup
            ? this.svgGroup.querySelectorAll('path')
            : null;
        if (!pathGroup) return;
        pathGroup.forEach(item => {
            item.style.stroke = '#ccc';
            item.style.strokeOpacity = '1';
            item.style.fill = '#ccc';
            item.style.pointerEvents = 'none';
            item.style.touchAction = 'none';
        });
    }

    async setThemeStyleForFilteredPlotHouseList() {
        if (this.filteredPlotHouseList && this.filteredPlotHouseList.length) {
            this.filteredPlotHouseList.forEach(item => {
                const id = item.plot.street ? item.plot.number : null;
                if (!id) return;

                const status = item.plot.status;
                const plotHouse: HTMLElement | null = this.svgGroup
                    ? this.svgGroup.querySelector(`#id_${id}`)
                    : null;
                if (!plotHouse)
                    return console.error(`plot #id_${id} not found`);
                plotHouse.style.stroke = StatusTheme[status];
                plotHouse.style.fill = StatusTheme[status];
                plotHouse.style.fillOpacity = '0.2';
                plotHouse.style.strokeWidth = '3px';
                plotHouse.style.pointerEvents = 'unset';
                plotHouse.style.touchAction = 'unset';
            });
        } else {
            if (this.svgGroup) {
                const plots = this.svgGroup.querySelectorAll('path');
                if (plots) {
                    plots.forEach(plot => {
                        plot.style.pointerEvents = 'none';
                        plot.style.touchAction = 'none';
                        plot.style.stroke = '#ccc';
                        plot.style.strokeWidth = '3px';
                        plot.style.fill = '#ccc';
                    });
                }
            }
        }
    }

    async onMouseOver(e: MouseEvent) {
        if (e.target instanceof Element) {
            const mapRect = cardSettingsStore.MAP_DOM_RECT;
            const target = e.target;

            const dataRect = target.getBoundingClientRect();
            const reselutDataRect = {
                bottom: dataRect.bottom,
                height: dataRect.height,
                left: dataRect.left,
                right: dataRect.right,
                top: dataRect.top,
                topMap: mapRect.top,
                width: dataRect.width,
                x: dataRect.x,
                y: dataRect.y - mapRect.y,
            };

            const pathId = target.id ? target.id : '';
            const id = pathId.replace('id_', '');

            cardSettingsStore.SET_STATUS_CARD_HOUSE_SHOW(true);
            cardSettingsStore.SET_POSITION_CARD_HOUSE(reselutDataRect);
            await landPlotStore.CREATE_LAND_PLOT_CARD_MINI(id);
        }
    }

    async onClick(e: MouseEvent) {
        if (e.target instanceof Element) {
            const target = e.target;
            const id = target.id ? target.id.substring(3) : '';
            if (!id) return;

            const res = await landPlotStore.CREATE_LAND_PLOT_CARD_IN_MODAL(id);
            if (res)
                cardSettingsStore.LAND_PLOT_CARD_IN_MODAL_SET_SHOW_STATUS(true);
        }
    }

    onMouseOut(e: MouseEvent) {
        if (e.target instanceof Element) {
            cardSettingsStore.SET_STATUS_CARD_HOUSE_SHOW(false);
        }
    }

    @Watch('filteredPlotHouseList')
    on() {
        this.resetThemeStyle();
        this.setThemeStyleForFilteredPlotHouseList();
    }

    mounted() {
        this.svgGroup = this.$el.querySelector('#event');
        if (!this.svgGroup) return;
        this.svgGroup.addEventListener('mouseover', e =>
            throttle(this.onMouseOver, 100)(e),
        );
        this.svgGroup.addEventListener('mouseout', e =>
            throttle(this.onMouseOut, 10)(e),
        );

        this.svgGroup.addEventListener('click', this.onClick);

        this.resetThemeStyle();
        this.setThemeStyleForFilteredPlotHouseList();
    }

    beforeDestroy() {
        if (!this.svgGroup) return;
        this.svgGroup.removeEventListener('mouseover', e =>
            throttle(this.onMouseOver, 100)(e),
        );
        this.svgGroup.removeEventListener('mouseout', e =>
            throttle(this.onMouseOut, 10)(e),
        );

        this.svgGroup.removeEventListener('click', this.onClick);
    }
}
