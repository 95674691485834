























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import CustomFilterButton from '@/components/commons/CustomControls/CustomFilterButton.vue';
import CustomListButton from '@/components/commons/CustomControls/CustomListButton.vue';
import CustomButtonBack from '@/components/commons/CustomControls/CustomButtonBack.vue';
import { landPlotStore } from '@/store/modules/LandPLot';

@Component({
    components: {
        CustomFilterButton,
        CustomListButton,
        CustomButtonBack,
    },
})
export default class MapHeader extends Vue {
    @Prop({ default: false }) changeStatusFilter!: boolean;

    tabs = [
        {
            id: 1,
            active: true,
            option: 'Показать на генплане',
            value: 'master-plan',
        },
        {
            id: 2,
            active: false,
            option: 'Показать на карте',
            value: 'map-plan',
        },
    ];
    filterActive = false;
    tabsIsOpen = false;

    get isShowInfoForDetailSection() {
        return landPlotStore.CURRENT_SECTION_ZONE ? true : false;
    }

    get currentTab() {
        return this.tabs.find(item => item.active === true);
    }

    backToFullMap() {
        landPlotStore.SET_CURRENT_SECTION_ZONE('');
    }

    setActive(index: number) {
        this.tabs = this.tabs.map(item => {
            item.active = false;
            return item;
        });
        this.tabs[index].active = true;
        this.tabsIsOpen = false;
        this.$emit('changeTab', this.tabs[index].value);
    }

    openFilter() {
        this.filterActive = true;
        this.$emit('openFilter');
    }

    openList() {
        this.$emit('openList');
    }

    onUploadSchemeImage() {
        const urlFromOtherDomain = `http${
            process.env.VUE_APP_COMPONENT_SECURE === 'true' ? 's' : ''
        }://${process.env.VUE_APP_COMPONENT_DOMAIN}/uploads/scheme_2D.tif`;

        fetch(urlFromOtherDomain)
            .then(res => res.blob())
            .then(blob => {
                const url = URL.createObjectURL(blob);
                const link = document.createElement('a');

                document.body.appendChild(link);
                link.setAttribute('download', 'scheme_2D');
                link.href = url;
                link.click();
                document.body.removeChild(link);
            });
    }

    @Watch('changeStatusFilter')
    onUpdate() {
        setTimeout(() => {
            this.filterActive = this.changeStatusFilter;
        });
    }
}
